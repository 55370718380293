import {InvoiceBase} from './invoice.base';

export class PurchaseInvoiceModel{
  PurchaseInvColDate: string = null;
  PurchaseInvColDocType: string = null;
  PurchaseInvColDocNo: string = null;
  PurchaseInvColSupplier: string = null;
  PurchaseInvColCurrency: string = null;
  PurchaseInvColPaymentType: string = null;
  PurchaseInvColPaymentAccount: string = null;
  PurchaseInvColApplyDate: string = null;
  PurchaseInvColDealType: string = null;
  PurchaseInvColVATNotes: string = null;
  PurchaseInvColProductCode: string = null;
  PurchaseInvColCode1: string = null;
  PurchaseInvColCode2: string = null;
  PurchaseInvColCode3: string = null;
  PurchaseInvColCode4: string = null;
  PurchaseInvColLineAmount: string = null;
  PurchaseInvColCurrencyDirectory: string = null;
}
