import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class HeightResizeService {
    @Output()
    onResize: EventEmitter<number> = new EventEmitter();

    resize(height: number) {
        this.onResize.emit(height);
    }
}
