import {EntityService} from '../base/entity.service';
import {GeneralEnterpriseCompany} from '../../../common/E1_DTO';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class EnterpriseCompaniesService extends EntityService<GeneralEnterpriseCompany> {
  constructor(_http: HttpClient, _router: Router) {
    super(_http, _router);
    this.serviceName = 'GeneralEnterpriseCompany';
  }
}
