<div class="sidemenu">
  <nav #sidebar class="sidebar" id="style-11">
    <div class="list-group">
      <a class="list-group-item" (click)="toggleFullText()">
        <i class="fa fa-fw fa-bars"></i>
      </a>
      <div style="background: #ccc; width: 100%; height: 2px;"></div>
      <a routerLink="/purchaseInvoices" [routerLinkActive]="['router-link-active']" class="list-group-item"
         title="{{ 'purchaseInvoices' | translate }}">
        <i class="fas fa-file-invoice-dollar"></i>
        <div class="srinkable" [class.srink]="isFullText">&nbsp;&nbsp;&nbsp;{{ 'purchaseInvoices' | translate
          }}
        </div>
      </a>
      <a routerLink="/invoices" [routerLinkActive]="['router-link-active']" class="list-group-item"
         title="{{ 'invoices' | translate }}">
        <i class="fas fa-align-center"></i>
        <div class="srinkable" [class.srink]="isFullText">&nbsp;&nbsp;&nbsp;{{ 'invoices' | translate
          }}
        </div>
      </a>
      <a routerLink="/vouchers" [routerLinkActive]="['router-link-active']" class="list-group-item"
         title="{{ 'vouchers' | translate }}">
        <i class="fas fa-file-invoice"></i>
        <div class="srinkable" [class.srink]="isFullText">&nbsp;&nbsp;&nbsp;{{ 'vouchers' | translate
          }}
        </div>
      </a>
      <a routerLink="/imports" [routerLinkActive]="['router-link-active']" class="list-group-item"
         title="{{ 'imports' | translate }}">
        <i class="fas fa-file-import"></i>
        <div class="srinkable" [class.srink]="isFullText">&nbsp;&nbsp;&nbsp;{{ 'imports' | translate
          }}
        </div>
      </a>
      <div style="background: #ccc; width: 100%; height: 2px;"></div>
      <a routerLink="/settings" [routerLinkActive]="['router-link-active']" class="list-group-item"
         title="{{ 'settings' | translate }}">
        <i class="fa fa-fw fa-cog"></i>
        <div class="srinkable" [class.srink]="isFullText">&nbsp;&nbsp;&nbsp;{{ 'settings' | translate }}</div>
      </a>
      <a routerLink="/logout" [routerLinkActive]="['router-link-active']" class="list-group-item"
         title="{{ 'logout' | translate }}">
        <i class="fa fa-fw fa-sign-out-alt"></i>
        <div class="srinkable" [class.srink]="isFullText">&nbsp;&nbsp;&nbsp;{{ 'logout' | translate }}</div>
      </a>
    </div>
  </nav>
</div>

