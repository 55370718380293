import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {SidebarVisibilityService} from './services/ui/sidebar.visibility.service';
import {LoadingVisibilityService} from './services/ui/loading.visibility.service';
import {WidthResizeService} from './services/ui/width.resize.service';
import {ScrolltoService} from './services/ui/scrollto.service';
import {AuthGuard} from './guard/auth.guard';
import {AuthenticationService} from './services/io/authentication.service';
import {AuthenticationTestService} from './services/io/authentication.test.service';
import {HeightResizeService} from './services/ui/height.resize.service';
import {ImportService} from './services/io/import.service';
import {EnterpriseCompaniesService} from './services/io/enterprise.companies.service';
import {EnterpriseCompanyLocationsService} from './services/io/enterprise.company.locations.service';
import {SettingsService} from './services/io/settings.service';
import {SetImportConfigurationService} from './services/io/setImportConfiguration.service';

@NgModule({
  imports: [
    SharedModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [SidebarVisibilityService,
    LoadingVisibilityService,
    WidthResizeService,
    HeightResizeService,
    ScrolltoService,
    AuthGuard,
    AuthenticationService,
    AuthenticationTestService,
    ImportService,
    EnterpriseCompaniesService,
    EnterpriseCompanyLocationsService,
    SettingsService,
    SetImportConfigurationService
  ]
})
export class SharedServicesModule {
  constructor() {
  }
}

