import {Injectable} from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {User} from '../../../common/user';

@Injectable()
export class AuthenticationTestService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  Test(): Observable<User> {
    return this.http.get<User>('/api/VerifyLogin/test', this.options).pipe(catchError(val => of(null)));
  }
}
