import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ImportResult} from '../../../common/import.result';
import {BaseService} from '../base/base.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Setting} from '../../../common/setting';

@Injectable()
export class SettingsService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  upsert(data: Setting): Observable<ImportResult> {
    return this.http.post<ImportResult>('/api/settings/upset', data, this.options)
      .pipe(catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(['logout']);
        }
        return of(null);
      }));
  }

  load(key: string): Observable<Setting> {
    return this.http.post<Setting>('/api/settings/load', {Key: key, Value: ''}, this.options)
      .pipe(catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(['logout']);
        }
        return of(null);
      }));
  }
}
