<div class="container">
  <div id="loginbox" style="margin-top:50px;" class="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">

    <h3>{{'login' | translate}}</h3>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="username">{{'username' | translate }}</label>
        <input type="text" formControlName="username" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
        <div *ngIf="submitted && f.username.errors" class="help-block">
          <div *ngIf="f.username.errors.required">{{'usernamerequired' | translate}}</div>
        </div>
      </div>
      <div class="form-group">
        <label for="password">{{'password' | translate }}</label>
        <input type="password" formControlName="password" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
        <div *ngIf="submitted && f.password.errors" class="help-block">
          <div *ngIf="f.password.errors.required">{{'passwordrequired' | translate}}</div>
        </div>
      </div>
      <div class="form-group">
        <button [disabled]="submitted" class="btn btn-primary">{{'login' | translate}}</button>
        <div *ngIf="submitted" style="float: right;"><i class="fa fa-spinner fa-pulse fa-fw"></i></div>
        <div *ngIf="error" class="alert alert-danger">{{error | translate}}</div>
      </div>
    </form>

  </div>
</div>
