interface IDictionary<T> {
  add(key: string, value: T): void;

  remove(key: string): void;

  containsKey(key: string): boolean;

  get(key: string): T;

  keys(): string[];

  values(): T[];
}

interface IKeyValueHolder<T> {
  [index: string]: T;
}

export class Dictionary<T> implements IDictionary<T> {
  items: IKeyValueHolder<T> = {};

  containsKey(key: string): boolean {
    return this.items.hasOwnProperty(key);
  }

  get(key: string): T {
    return this.items[key];
  }

  add(key: string, value: T): void {
    this.items[key] = value;
  }

  remove(key: string): void {
    delete this.items[key];
  }

  keys(): string[] {
    const keySet: string[] = [];

    for (const prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        keySet.push(prop);
      }
    }

    return keySet;
  }

  values(): T[] {
    const values: T[] = [];

    for (const prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        values.push(this.items[prop]);
      }
    }

    return values;
  }


}
