import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {LoginComponent} from './pages/login/login.component';
import {LogoutComponent} from './pages/logout/logout.component';
import {SharedModule} from '../../shared/shared.module';
import {AuthenticationRoutingModule} from './authentication.routing';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    SharedModule,
    AuthenticationRoutingModule
  ],
  exports: [
    LogoutComponent,
    LogoutComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AuthenticationModule {
}
