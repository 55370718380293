import {Compiler, Component, ViewChild} from '@angular/core';
import {SidebarComponent} from './shared.components/sidebar/sidebar.component';
import {SidebarVisibilityService} from './shared.services/services/ui/sidebar.visibility.service';
import {LoadingVisibilityService} from './shared.services/services/ui/loading.visibility.service';
import {WidthResizeService} from './shared.services/services/ui/width.resize.service';
import {ScrolltoService} from './shared.services/services/ui/scrollto.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationTestService} from './shared.services/services/io/authentication.test.service';
import {Router} from '@angular/router';
import {HeightResizeService} from './shared.services/services/ui/height.resize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('sidebar') sidebar: SidebarComponent;
  @ViewChild('mainContainer') container;
  @ViewChild('scroll') scroll;

  sidebarVisible = true;
  loadingVisible = false;

  pageHeight = 800;
  margin = 70;

  containerStyle = {
    'margin-left': '350px',
    'height': '500px'
  };

  pageStyle = {
    'height': 500 + 'px',
    'white-space': 'nowrap'
  };


  private _pageWidth: any;

  get pageWidth() {
    return this._pageWidth;
  }

  set pageWidth(val) {
    this._pageWidth = val;
  }

  closeSidebar(e) {
    if (!this.sidebar.isActive) {
      const dom: any = document.querySelector('body');
      dom.classList.remove('push-right');
    }
  }

  constructor(private _compiler: Compiler, private translate: TranslateService,
              private testService: AuthenticationTestService,
              private router: Router,
              private sbv: SidebarVisibilityService,
              private lvb: LoadingVisibilityService,
              private srs: WidthResizeService,
              private hrs: HeightResizeService,
              private sts: ScrolltoService) {

    this.translate.setDefaultLang('bg');
    this.translate.use('bg');

    sbv.selectVisibility.subscribe(v => {
      this.sidebarVisible = v;
    });
    sbv.marginChange.subscribe(amount => {
      this.margin = amount;
      this.setContentStyle();
    });
    lvb.selectVisibility.subscribe(v => {
      if (this.sidebarVisible) {
        this.loadingVisible = v;
      } else {
        this.loadingVisible = false;
      }
    });

    sts.onScrollTo.subscribe(v => {
      if (v === 'start') {
        this.scroll.instance.scrollTo({left: 0, top: 0});
        return;
      }
      if (v === 'end') {
        this.scroll.instance.scrollTo({left: 9999999, top: 0});
        return;
      }

      if (v !== '') {
        this.scroll.instance.scrollTo({left: v, top: 0});
        return;
      }
      const sw = 99999999;
      if (this.scroll.instance.scrollLeft() === 0) {
        this.sts.isOnStart = false;
        this.scroll.instance.scrollBy({left: sw, top: 0});
      } else {
        this.sts.isOnStart = true;
        this.scroll.instance.scrollTo({left: 0, top: 0});
      }
    });

    this.pageHeight = window.innerHeight;
    this.setContentStyle();
  }

  onResize(e) {
    this.srs.resize(e.target.innerWidth);
    this.hrs.resize(e.target.innerHeight - 120);
    this.pageHeight = e.target.innerHeight;
    this.setContentStyle();
  }

  private setContentStyle() {
    this.containerStyle = {
      'margin-left': this.margin + 'px',
      'height': (this.pageHeight - 100) + 'px'
    };
    this.pageStyle = {
      'height': (this.pageHeight - 100) + 'px',
      'white-space': 'nowrap'
    };
    this.pageWidth = window.innerWidth - this.margin;
  }
}
