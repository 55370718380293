export class VoucherModel {
  VoucherColDate: string = null;
  VoucherColNotes: string = null;
  VoucherColAccount: string = null;
  VoucherColCode1: string = null;
  VoucherColCode2: string = null;
  VoucherColCode3: string = null;
  VoucherColCode4: string = null;
  VoucherColDebit: string = null;
  VoucherColCredit: string = null;
}


