// const URL = '/api/';
import {Component, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {from, fromEvent, Observable, Subject, timer} from 'rxjs';
import {concatMap, map, mergeMap, takeUntil} from 'rxjs/operators';
import {ImportService} from '../../../../shared.services/services/io/import.service';
import {ImportResult} from '../../../../common/import.result';
import {TranslateService} from '@ngx-translate/core';
import {Dictionary} from '../../../../common/dictionary';
import {ActivatedRoute, Router} from '@angular/router';
import {String} from 'typescript-string-operations';
import {Guid} from '../../../../common/guid';
import {ProgressData} from '../../../../common/progress.data';
import {LoadingVisibilityService} from '../../../../shared.services/services/ui/loading.visibility.service';
import {FileUpload} from 'primeng/fileupload';

@Component({
  templateUrl: 'vouchers.component.html'
})
export class VouchersComponent {
  progresses = new Dictionary<ProgressData>();
  keys = new Dictionary<boolean>();
  data: any;

  constructor(private importService: ImportService, public translate: TranslateService, private router: Router,
              route: ActivatedRoute,
              private lvs: LoadingVisibilityService) {
    lvs.clearLoadings();
  }

  removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(null, index);
  }

  getSizeInMegaBytes(file: File) {
    return file ? file.size / 1000000 : 0;
  }

  onFileChange(evt: any, uploader: FileUpload) {
    const that = this;

    const files = evt.files as FileList;

    this.lvs.show('loadingFiles');

    from(files).pipe(
      mergeMap(file =>
        new Observable<any>(result => {
          const reader: FileReader = new FileReader();
          reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary', cellDates: true, dateNF: 'yyyy/mm/dd;@'});

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = (XLSX.utils.sheet_to_json(ws, {header: 1, raw: false}));
            this.data = this.data.filter(ar => ar.length !== 0);

            const key = Guid.newGuid();
            that.importService.importVouchers(this.data, key, file.name).subscribe(succ => {
              result.next(succ);
            });
          };
          reader.readAsBinaryString(file);
        })
      )
    ).subscribe((succ) => {
      that.lvs.clearLoadings();
      this.router.navigate(['imports']);
    }, err => {
      that.lvs.clearLoadings();
    });
  }
}

