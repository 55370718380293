import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class LoadingVisibilityService {
  @Output() selectVisibility = new EventEmitter();
  @Output() selectLoadPanelEnabled = new EventEmitter();

  values = [];
  closeLoadingCallback = () => { };

  clearLoadings() {
    this.selectLoadPanelEnabled.emit(true);
    this.values = [];
    this.selectVisibility.emit(false);
  }

  show(value) {
    this.selectVisibility.emit(true);
    this.values.push(value);
  }

  hide(value) {
    if (value === '') {
      this.values = [];
      this.finishHide();
    }
    const index = this.values.indexOf(value);
    if (index >= 0) {
      this.values.splice(index, 1);
    }
    if (this.values.length === 0) {
      this.finishHide();
    }
  }

  private finishHide() {
    this.selectVisibility.emit(false);
    this.selectLoadPanelEnabled.emit(false);
    this.closeLoadingCallback();
  }
}
