<div style="width: 100%; height: 100%; overflow: scroll;">
  <p-panel header="{{'imports'| translate}}">
    <div *ngFor="let status of statuses">
      <h2>{{status.FileName}}</h2>
      <div *ngIf="status.Error">
        <p-accordion>
          <p-accordionTab header="{{ 'errorHeader' | translate}}">
          <div style="word-wrap: break-word;" [innerHTML]="status.Error"></div>
          </p-accordionTab>
        </p-accordion>
      </div>
      <p-progressBar *ngIf="!status.Error" [value]="calculatePercentage(status)"></p-progressBar>
      <h3 *ngIf="!status.Error">{{status.ItemProcessed}} / {{status.ItemCount}} {{'rows' |
        translate}}</h3>
      <div *ngIf="status.Finished && (!status.Error || status.Error === '')">{{'successfulImport' | translate}}</div>
      <br/>
      <div>
        <button *ngIf="!status.Finished" pButton type="button" label="{{'cancel' | translate}}"
                (click)="cancelImport(status)"></button>
        <button *ngIf="status.Finished" pButton type="button" label="{{'remove' | translate}}"
                (click)="cancelImport(status)"></button>
      </div>
      <br/>
    </div>
  </p-panel>
</div>
