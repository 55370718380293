import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SidebarVisibilityService} from '../../shared.services/services/ui/sidebar.visibility.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    @ViewChild('sidebar') sidebar;

    isActive = false;
    showMenu = '';
    isFullText = true;

    constructor(public translate: TranslateService, private svb: SidebarVisibilityService) {

    }

    eventCalled() {
        this.isActive = !this.isActive;
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    toggleFullText() {
        this.isFullText = !this.isFullText;
        if (this.isFullText) {
            this.svb.changeMargin(350);
        } else {
            this.svb.changeMargin(70);
        }
    }

    ngOnInit() {
        this.svb.changeMargin(350);
    }
}
