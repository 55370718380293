<div style="width: 100%; height: 100%; overflow: scroll;">
  <p-accordion [multiple]="false">
    <p-panel header="{{'settings'| translate}}">
      <div class="container">
        <div class="row">
          <div class="col-md-5 customSettings">
            <h5 class="first font-bold">{{'enterprisecompany' | translate}}</h5>
            <p-dropdown [options]="enterpriseCompanies"
                        [(ngModel)]="enterpriseCompany"
                        placeholder="{{'selectEnterpriseCompany' | translate}}"
                        optionLabel="Company.Name">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 customSettings">
            <h5 class="font-bold">{{'enterprisecompanyLocation' | translate}}</h5>
            <p-dropdown [options]="enterpriseCompanyLocations"
                        [(ngModel)]="enterpriseCompanyLocation"
                        placeholder="{{'selectEnterpriseCompanyLocation' | translate}}"
                        optionLabel="LocationName">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <p-button label="{{'save' | translate}}" (onClick)="onSave($event)"></p-button>
            <br/><br/>
            <div *ngIf="companySaveMessage" class="alert alert-success custom-msg">{{'companySaveMessage' | translate}}</div>
          </div>
        </div>
      </div>
    </p-panel>
    <p-accordionTab header="{{'purchaseInvoiceChange'| translate}}">
      <div class="container">
        <div class="row" *ngFor="let purchaseInvoiceRow of purchaseInvoiceRows; index as i">
          <div class="col-md-5 customSettings">
            <h5 class="first font-bold">{{purchaseInvoiceRow | translate}}</h5>
            <p-dropdown *ngIf="i <= 15" [style]="{'width':'67%'}"
                        [options]="purchaseInvoiceColumnOptions"
                        placeholder="{{ 'defaultValue' | translate }}{{i + 1}}"
                        [ngModel]="currentPurchaseInvoiceConf[purchaseInvoiceRow]"
                        [showClear]="true"
                        (onChange)="collectPurchaseInvoicesColumns($event.value, purchaseInvoiceRow)">
            </p-dropdown>
            <p-dropdown *ngIf="i > 15" [style]="{'width':'67%'}"
                        [options]="purchaseInvoiceColumnOptions"
                        placeholder="{{'noDefaultValue' | translate}}"
                        [ngModel]="currentPurchaseInvoiceConf[purchaseInvoiceRow]"
                        [showClear]="true"
                        (onChange)="collectPurchaseInvoicesColumns($event.value, purchaseInvoiceRow)"
                        appendTo="body">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <p-button label="{{'save' | translate}}" (onClick)="onSavePurchaseInvoicesColumns(currentPurchaseInvoiceConf)"></p-button>
            <br/><br/>
            <div class="alert alert-success custom-msg" *ngIf="purchaseInvoiceSaveMessage">{{'purchaseInvoiceSaveMessage' | translate}}</div>
          </div>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="{{'invoiceChange'| translate}}">
      <div class="container">
        <div class="row" *ngFor="let invoiceRow of invoiceRows; index as i">
          <div class="col-md-5 customSettings">
            <h5 class="first font-bold">{{invoiceRow | translate}}</h5>
            <p-dropdown *ngIf="i <= 15" [style]="{'width':'67%'}"
                        [options]="invoiceColumnOptions"
                        placeholder="{{ 'defaultValue' | translate }}{{i + 1}}"
                        [ngModel]="currentInvoiceConf[invoiceRow]"
                        [showClear]="true"
                        (onChange)="collectInvoicesColumns($event.value, invoiceRow)">
            </p-dropdown>
            <p-dropdown *ngIf="i > 15" [style]="{'width':'67%'}"
                        [options]="invoiceColumnOptions"
                        placeholder="{{'noDefaultValue' | translate}}"
                        [ngModel]="currentInvoiceConf[invoiceRow]"
                        [showClear]="true"
                        (onChange)="collectInvoicesColumns($event.value, invoiceRow)"
                        appendTo="body">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <p-button label="{{'save' | translate}}" (onClick)="onSaveInvoicesColumns(currentInvoiceConf)"></p-button>
            <br/><br/>
            <div class="alert alert-success custom-msg" *ngIf="invoiceSaveMessage">{{'invoiceSaveMessage' | translate}}</div>
          </div>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="{{'vouchersOptions'| translate}}">
      <div class="container">
        <div class="row" *ngFor="let voucherRow of vouchersRows; index as i">
          <div class="col-md-5 customSettings">
            <h5 class="first font-bold">{{voucherRow | translate}}</h5>
            <p-dropdown [style]="{'width':'67%'}"
                        [options]="vouchersColumnOptions"
                        placeholder="{{ 'defaultValue' | translate }}{{i + 1}}"
                        [ngModel]="currentVoucherConf[voucherRow]"
                        [showClear]="true"
                        (onChange)="collectVouchersColumns($event.value, voucherRow)"
                        appendTo="body">
            </p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <p-button label="{{'save' | translate}}" (onClick)="onSaveVouchersColumns(currentVoucherConf)"></p-button>
            <br/><br/>
            <div class="alert alert-success custom-msg" *ngIf="voucherSaveMessage">{{'voucherSaveMessage' | translate}}</div>
          </div>
        </div>
        <br/><br/><br/>
      </div>
      </p-accordionTab>
  </p-accordion>
</div>


