import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationTestService} from '../services/io/authentication.test.service';
import {map} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private testService: AuthenticationTestService) {
  }

  canActivate(): Observable<boolean> {
    return this.testService.Test().pipe(
      map(val => {
        if (val) {
          localStorage.setItem('loggedin', '1');
          localStorage.setItem('currentUser', JSON.stringify({username: val.user}));
          return true;
        }
        localStorage.removeItem('loggedin');
        localStorage.removeItem('currentUser');
        document.location.href = 'api/login';
        return false;
      })
    );
  }
}
