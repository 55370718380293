import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../../shared.services/guard/auth.guard';
import {SettingsComponent} from './pages/settings/settings.component';
import {VouchersComponent} from './pages/vouchers/vouchers.component';
import {ImportsComponent} from './pages/imports/imports.component';
import {PurchaseInvoicesComponent} from './pages/purchaseInvoices/purchase.invoices.component';
import {InvoicesComponent} from './pages/invoices/invoices.component';

const startingRoutes: Routes = [
  {path: 'imports', component: ImportsComponent, canActivate: [AuthGuard]},
  {path: 'vouchers', component: VouchersComponent, canActivate: [AuthGuard]},
  {path: 'purchaseInvoices', component: PurchaseInvoicesComponent, canActivate: [AuthGuard]},
  {path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard]},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    RouterModule.forChild(startingRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class StartingRoutingModule {

}
