import {Injectable} from '@angular/core';
import {BaseService} from '../base/base.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ImportResult} from '../../../common/import.result';
import {Router} from '@angular/router';
import {ProgressData} from '../../../common/progress.data';
import {StatusModel} from '../../../common/status.model';

@Injectable()
export class ImportService extends BaseService {
    constructor(private http: HttpClient, private router: Router) {
        super();
    }

    importPurchaseInvoices(data: any, key: string, fileName: string): Observable<boolean> {
        return this.http.post('/api/files/purchaseInvoices', {Data: data, Key: key, FileName: fileName}, this.options)
            .pipe(catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        this.router.navigate(['logout']);
                    }
                    return of(false);
                }),
                map(() => {
                    return true;
                }));
    }

  importInvoices(data: any, key: string, fileName: string): Observable<boolean> {
    return this.http.post('/api/files/invoices', {Data: data, Key: key, FileName: fileName}, this.options)
      .pipe(catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.router.navigate(['logout']);
          }
          return of(false);
        }),
        map(() => {
          return true;
        }));
  }

    importVouchers(data: any, key: string, fileName: string): Observable<boolean> {
        return this.http.post('/api/files/importVouchers', {Data: data, Key: key, FileName: fileName}, this.options)
            .pipe(catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        this.router.navigate(['logout']);
                    }
                    return of(false);
                }),
                map(() => {
                    return true;
                }));
    }

    statuses(): Observable<StatusModel[]> {
        return this.http.get<StatusModel[]>('/api/files/statuses', this.options)
            .pipe(catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.router.navigate(['logout']);
                }
                return of(null);
            }));
    }

    progress(key: string): Observable<ProgressData> {
        return this.http.post<ProgressData>('/api/files/progress', {Key: key}, this.options)
            .pipe(catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.router.navigate(['logout']);
                }
                return of(null);
            }));
    }

    result(key: string): Observable<ImportResult> {
        return this.http.post<number>('/api/files/result', {Key: key}, this.options)
            .pipe(catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.router.navigate(['logout']);
                }
                return of(null);
            }));
    }

    cancel(key: string): Observable<boolean> {
        return this.http.post<boolean>('/api/files/cancel', {Key: key}, this.options)
            .pipe(catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        this.router.navigate(['logout']);
                    }
                    return of(null);
                }),
                map(() => {
                    return true;
                }));
    }

    remove(key: string): Observable<boolean> {
        return this.http.post<boolean>('/api/files/remove', {Key: key}, this.options)
            .pipe(catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        this.router.navigate(['logout']);
                    }
                    return of(null);
                }),
                map(() => {
                    return true;
                }));
    }
}
