import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
  public token: string;

  constructor(private http: HttpClient) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  login(username, password): Observable<boolean> {

    const data = JSON.stringify({userName: username, password: password, language: 'BG'});

    const args = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    return this.http.post('/api/auth/login', data, args).pipe(
      map((response) => {
        // login successful if there's a jwt token in the response
        const token = response['Token'];
        if (token && token !== '') {
          // set token property
          this.token = token;

          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify({username: username, token: token}));

          // return true to indicate successful login
          return true;
        }
        // return false to indicate failed login
        return false;
      }),
      catchError((val: HttpErrorResponse) => {
        if (val.status === 401) {
          return of(false);
        }
        return of(null);
      }));
  }

  logout(): Observable<boolean> {
    const data = '';

    const args = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'DI ' + this.token})
    };

    localStorage.removeItem('currentUser');
    localStorage.clear();

    return this.http.post('/api/auth/logout', data, args).pipe(
      map((response) => {
        this.token = null;
        return true;
      }), catchError(val => of(false)));
  }
}
