import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class WidthResizeService {
    @Output()
    onResize: EventEmitter<number> = new EventEmitter();

    resize(width: number) {
        this.onResize.emit(width);
    }
}
