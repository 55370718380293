import {Component, EventEmitter, NgZone, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../../shared.services/services/io/authentication.service';
import {SidebarVisibilityService} from '../../../../shared.services/services/ui/sidebar.visibility.service';
import {LoadingVisibilityService} from '../../../../shared.services/services/ui/loading.visibility.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;

  @Output() loggedIn = new EventEmitter<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sbv: SidebarVisibilityService,
    private lvb: LoadingVisibilityService,
    private ngZone: NgZone,
    public translate: TranslateService) {
  }

  ngOnInit() {
    // reset login status
    this.lvb.clearLoadings();
    this.sbv.hide();
    // reset login status
    this.authenticationService.logout().subscribe(data => {
    });

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const result = await this.authenticationService.login(this.f.username.value, this.f.password.value).toPromise();
    localStorage.removeItem('loggedin');
    if (result === null) {
      this.error = 'server_error';
    } else if (result === true) {
      this.error = null;
      this.sbv.show();
      localStorage.setItem('loggedin', '1');
      this.router.navigate([this.returnUrl]);
    } else {
      this.error = 'err_user_pass';
    }
    this.submitted = false;
    this.loggedIn.emit(this.error == null);
  }
}
