import {SharedModule} from '../../shared/shared.module';
import {StartingRoutingModule} from './starting.routing';
import {SettingsComponent} from './pages/settings/settings.component';
import {VouchersComponent} from './pages/vouchers/vouchers.component';
import {ImportsComponent} from './pages/imports/imports.component';
import {PurchaseInvoicesComponent} from './pages/purchaseInvoices/purchase.invoices.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {AccordionModule} from 'primeng/accordion';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { InvoicesComponent } from './pages/invoices/invoices.component';

@NgModule({
  declarations: [
    PurchaseInvoicesComponent,
    VouchersComponent,
    SettingsComponent,
    ImportsComponent,
    InvoicesComponent
  ],
  imports: [
    SharedModule,
    StartingRoutingModule,
    InputNumberModule,
    AccordionModule
  ],
  exports: [
    PurchaseInvoicesComponent,
    VouchersComponent,
    ImportsComponent,
    SettingsComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class StartingModule {
}
