import { BrowserModule } from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { AppComponent } from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {SharedServicesModule} from './shared.services/shared.services.module';
import {SharedComponentsModule} from './shared.components/shared.components.module';
import {SharedModule} from './shared/shared.module';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {StartingModule} from './modules/starting/starting.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    SharedServicesModule,
    SharedComponentsModule,
    AuthenticationModule,
    StartingModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
