import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class ScrolltoService {
    isOnStart = true;

    onScrollTo = new EventEmitter();

    scrollTo(v) {
        this.onScrollTo.emit(v);
    }
}
