<div *ngIf="loadingVisible" class="loading">
  <div class="angularLoading">
    <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
  </div>
</div>
<div (window:resize)="onResize($event)">
  <app-header></app-header>
  <section *ngIf="sidebarVisible">
    <app-sidebar #sidebar></app-sidebar>
  </section>
  <section #mainContainer class="main-container" [ngStyle]="containerStyle">
    <!--<div #scroll [height]="pageHeight-100"-->
    <!--[width]="pageWidth - 40"-->
    <!--direction="both"-->
    <!--showScrollbar="always">-->

    <div #scroll>
      <div #page_content id="page_content" [ngStyle]="pageStyle">
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
</div>
