import {InvoiceBase} from './invoice.base';

export class InvoiceModel{
  InvColDate: string = null;
  InvColDocType: string = null;
  InvColDocNo: string = null;
  InvColCustomer: string = null;
  InvColCurrency: string = null;
  InvColPaymentType: string = null;
  InvColPaymentAccount: string = null;
  InvColDeliveryDate: string = null;
  InvColDealType: string = null;
  InvColProductCode: string = null;
  InvColCode1: string = null;
  InvColCode2: string = null;
  InvColCode3: string = null;
  InvColCode4: string = null;
  InvColLineAmount: string = null;
  InvColQuantity: string = null;
  InvColCurrencyDirectory: string = null;
  InvColDocumentNotes: string = null;
}
