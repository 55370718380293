<div style="width: 100%; height: 100%; overflow: scroll;">
  <p-panel header="{{'vouchers'| translate}}">
    <p-fileUpload #fileUploader name="importFiles"
                  customUpload="true"
                  chooseLabel="{{'chooseFiles' | translate}}"
                  uploadLabel="{{'import' | translate }}"
                  cancelLabel="{{'cancel' | translate}}"
                  (uploadHandler)="onFileChange($event, fileUploader)"
                  multiple="multiple">
      <ng-template let-file pTemplate='file'>
        <h3>{{file.name}}</h3>
        <div>{{getSizeInMegaBytes(file) | number:'1.2-2'}} MB</div>
        <div>
          <button pButton type="button" label="{{'remove' | translate}}"
                  (click)="removeFile(file, fileUploader)"></button>
        </div>
      </ng-template>
    </p-fileUpload>
  </p-panel>
</div>
