import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class SidebarVisibilityService {
    @Output() marginChange = new EventEmitter();
    @Output() selectVisibility = new EventEmitter();

    show() {
        this.selectVisibility.emit(true);
    }

    hide() {
        this.selectVisibility.emit(false);
    }

    changeMargin(amount) {
        this.marginChange.emit(amount);
    }
}
