import {BaseService} from './base.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BaseDTO} from '../../../common/E1_DTO';
import {Observable, of} from 'rxjs';
import {ImportResult} from '../../../common/import.result';
import {catchError} from 'rxjs/operators';
import {ListResult} from '../../../common/list.result';
import {ItemResult} from '../../../common/item.result';
import {serialize} from '@angular/compiler/src/i18n/serializers/xml_helper';
import {Router} from '@angular/router';

export class EntityService<T extends BaseDTO> extends BaseService {
  protected serviceName = '';

  constructor(private http: HttpClient,
              private router: Router) {
    super();
  }

  load(filter: any = {}): Observable<ListResult<T>> {
    const url = `/api/${this.serviceName}/load`;
    return this.http.post<ListResult<T>>(url, filter, this.options)
      .pipe(catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.router.navigate(['logout']);
          }
          return of(null);
        }
      ));
  }

  find(id: string): Observable<ItemResult<T>> {
    const url = `/api/${this.serviceName}/find`;
    return this.http.post<ItemResult<T>>(url, {Id: id}, this.options)
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.router.navigate(['logout']);
        }
        return of(null);
      }));
  }
}
