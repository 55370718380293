import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../shared.services/services/io/authentication.service';
import {Router} from '@angular/router';

@Component({
  template: '<div></div>'
})

export class LogoutComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {

  }

  ngOnInit(): void {
    document.location.href = 'api/logout';
  }
}
