<div class="pos-f-t fixed-top header">
  <nav class="navbar navbar-light bg-inverse navbar-toggleable-md">
    <!--<button class="navbar-toggler navbar-toggler-right" (click)="toggleSidebar()">-->
    <!--<span class="navbar-toggler-icon"></span>-->
    <!--</button>-->
    <a class="navbar-brand" routerLink="/" [routerLinkActive]="['router-link-active']"><img height="20px" src="../../../assets/favicon.png"/> Document Importer</a>
    <div class="collapse navbar-collapse">
      <!--<form class="form-inline my-2 my-lg-0">-->
      <!--<input class="form-control mr-sm-2" type="text" placeholder="Search">-->
      <!--</form>-->
      <ul class="navbar-nav ml-auto mt-2 mt-md-0">
        <li class="nav-item dropdown" ngbDropdown>
          <a id="btnDropDownLanguage" href="javascript:void(0)" class="nav-link" ngbDropdownToggle>
            {{ 'language' | translate }} <b class="caret"></b>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a id="btnEN" class="dropdown-item" href="javascript:void(0)" (click)="changeLang('en')">English</a>
            <a id="btnBG" class="dropdown-item" href="javascript:void(0)" (click)="changeLang('bg')">Bulgarian</a>
          </div>
        </li>
<!--        <li class="nav-item dropdown" *ngIf="loggedin" ngbDropdown>-->
<!--          <a href="javascript:void(0)" class="nav-link" ngbDropdownToggle>-->
<!--            <i class="fa fa-user-circle"></i> {{username}} <b class="caret"></b>-->
<!--          </a>-->
<!--          <div class="dropdown-menu dropdown-menu-right">-->
<!--            <a routerLink="/logout" class="dropdown-item">-->
<!--              <i class="fa fa-fw fa-lock"></i>&nbsp;{{ 'logout' | translate }}-->
<!--            </a>-->
<!--          </div>-->
<!--        </li>-->
      </ul>
    </div>
  </nav>
</div>
