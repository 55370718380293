import {Component, OnDestroy, OnInit} from '@angular/core';
import {ImportService} from '../../../../shared.services/services/io/import.service';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {StatusModel} from '../../../../common/status.model';

@Component({
  templateUrl: 'imports.component.html'
})
export class ImportsComponent implements OnInit, OnDestroy {
  private updateTimer = timer(50, 500);
  private subject = new Subject();
  private subscription: Subscription = null;
  statuses: StatusModel[] = [];

  constructor(private importService: ImportService, public translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.updateTimer.pipe(takeUntil(this.subject))
      .subscribe(val => {
        this.importService.statuses().subscribe(statuses => {
          if (statuses) {
            const shouldUpdate = this.statuses.filter(s => !s.Finished).length > 0 ||
                                 this.statuses.length !== statuses.length;

            if (shouldUpdate) {
              this.statuses = statuses;
            }
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.subject.next();
    this.subscription.unsubscribe();
  }

  calculatePercentage(status: StatusModel) {
    return Math.round(status.ItemProcessed * 100 / status.ItemCount);
  }

  cancelImport(status: StatusModel) {
    this.importService.cancel(status.StatusKey).subscribe((val) => {
      }
    );
  }
}
