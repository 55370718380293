import {AfterViewInit, Component, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GeneralContactsCompanyLocation, GeneralEnterpriseCompany} from '../../../../common/E1_DTO';
import {Router} from '@angular/router';
import {EnterpriseCompanyLocationsService} from '../../../../shared.services/services/io/enterprise.company.locations.service';
import {EnterpriseCompaniesService} from '../../../../shared.services/services/io/enterprise.companies.service';
import {SettingsService} from '../../../../shared.services/services/io/settings.service';
import {LoadingVisibilityService} from '../../../../shared.services/services/ui/loading.visibility.service';
import {Guid} from '../../../../common/guid';
import {SetImportConfigurationService} from '../../../../shared.services/services/io/setImportConfiguration.service';
import {VoucherModel} from '../../../../common/voucher.model';
import {PurchaseInvoiceModel} from '../../../../common/purchase.invoice.model';
import {InvoiceModel} from '../../../../common/invoice.model';

@Component({
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewInit {
  private _enterpriseCompany: GeneralEnterpriseCompany = null;
  private _enterpriseCompanyLocation: GeneralContactsCompanyLocation = null;

  @Output() rowOnChange: string;
  @Output() selectedRow: string;
  enterpriseCompanies: GeneralEnterpriseCompany[] = [];
  enterpriseCompanyLocations: GeneralContactsCompanyLocation[] = [];
  purchaseInvoiceRows: string[];
  purchaseInvoiceColumnOptions = [];
  invoiceColumnOptions = [];
  vouchersRows: string[];
  vouchersColumnOptions = [];
  currentPurchaseInvoiceConf = new PurchaseInvoiceModel();
  currentVoucherConf = new VoucherModel();
  currentInvoiceConf = new InvoiceModel();
  selectedOption: any;
  defaultValue: string;
  purchaseInvoicePlaceholder: string;
  invoiceRows: string[];
  invoiceSaveMessage: string;
  purchaseInvoiceSaveMessage: string;
  voucherSaveMessage: string;
  companySaveMessage: string;
  set enterpriseCompany(val: GeneralEnterpriseCompany) {
    this._enterpriseCompany = val;
    this.enterpriseCompanyLocations = [];
    this.ecls.load({SearchExpr: 'company.id', SearchValue: val.Company.Id}).subscribe(data => {
      if (data) {
        this.enterpriseCompanyLocations = data.List;
      } else {
        this.enterpriseCompanyLocations = [];
      }
    });
  }

  get enterpriseCompany(): GeneralEnterpriseCompany {
    return this._enterpriseCompany;
  }

  set enterpriseCompanyLocation(val: GeneralContactsCompanyLocation) {
    this._enterpriseCompanyLocation = val;
  }

  get enterpriseCompanyLocation(): GeneralContactsCompanyLocation {
    return this._enterpriseCompanyLocation;
  }

  constructor(public translate: TranslateService,
              private ecs: EnterpriseCompaniesService,
              private ecls: EnterpriseCompanyLocationsService,
              private settingsService: SettingsService,
              private router: Router,
              private lvs: LoadingVisibilityService,
              private setImportConfigurationService: SetImportConfigurationService) {
    lvs.clearLoadings();
  }

  ngOnInit(): void {

    this.purchaseInvoiceRows = Object.getOwnPropertyNames(new PurchaseInvoiceModel());
    for (let i = 1; i <= this.purchaseInvoiceRows.length; i++) {// add options to the array
      this.purchaseInvoiceColumnOptions.push('' + i);
    }

    this.invoiceRows = Object.getOwnPropertyNames(new InvoiceModel());
    for (let i = 1; i <= this.invoiceRows.length; i++) {// add options to the array
      this.invoiceColumnOptions.push('' + i);
    }

    this.vouchersRows = Object.getOwnPropertyNames(new VoucherModel());
    for (let i = 1; i <= 9; i++) { // add options to the array
      this.vouchersColumnOptions.push('' + i);
    }
    const lvsId = Guid.newGuid();
    this.lvs.show(lvsId);
    this.ecs.load().subscribe(data => {
      if (data) {
        this.enterpriseCompanies = data.List;
        this.settingsService.load('EDI/Last_Used_Enterprise_Company_Id').subscribe(dataEc => {
          if (dataEc) {
            this.ecs.find(dataEc.Value).subscribe(ecdata => {
              if (ecdata) {
                this.enterpriseCompany = ecdata.Item;
                this.settingsService.load('EDI/Last_Used_Enterprise_Company_Location_Id/' + this.enterpriseCompany.Id)
                  .subscribe(dataEcl => {
                    if (!dataEcl) {
                      this.lvs.hide(lvsId);
                    }
                    this.ecls.find(dataEcl.Value).subscribe(
                      ecldata => {
                        if (ecldata) {
                          this.enterpriseCompanyLocation = ecldata.Item;
                        }
                        this.lvs.hide(lvsId);
                      }
                    );
                  });
              } else {
                this.lvs.hide(lvsId);
              }
            });
          } else {
            this.lvs.hide(lvsId);
          }
        });
      } else {
        this.lvs.hide(lvsId);
      }
    });
  }

  onSave(e) {
    const enterpriseCompanyId = this.enterpriseCompany.Id;
    this.settingsService.upsert({Key: 'EDI/Last_Used_Enterprise_Company_Id', Value: enterpriseCompanyId}).subscribe(res => {
      this.settingsService.upsert({
        Key: 'EDI/Last_Used_Enterprise_Company_Location_Id/' + enterpriseCompanyId,
        Value: this.enterpriseCompanyLocation.Id
      })
        .subscribe(val => {
            this.companySaveMessage = 'successfulSavedCompany';
            setTimeout(() => {
              this.companySaveMessage = null;
            }, 4000);
        });
    });
  }

  onSaveVouchersColumns(data) {
    this.setImportConfigurationService.pushVouchersConfiguration(data).subscribe(res => {
      this.voucherSaveMessage = 'successfulSavedVoucherConfig';
      setTimeout(() => {
        this.voucherSaveMessage = null;
      }, 4000);
    });
  }

  onSavePurchaseInvoicesColumns(data) {
    this.setImportConfigurationService.pushPurchaseInvoicesConfiguration(data).subscribe(res => {
      if (res){
        this.purchaseInvoiceSaveMessage = 'successfulSavedPurchaseInvoiceConfig';
        setTimeout(() => {
          this.purchaseInvoiceSaveMessage = null;
        }, 4000);
      }
    });
  }

  onSaveInvoicesColumns(data) {
    this.setImportConfigurationService.pushInvoicesConfiguration(data).subscribe(res => {
      if (res) {
        this.invoiceSaveMessage = 'successfulSavedInvoiceConfig';
        setTimeout(() => {
          this.invoiceSaveMessage = null;
        }, 4000);
      }
    });
  }

  collectPurchaseInvoicesColumns(value, purchaseInvoiceRow: any) {
    this.currentPurchaseInvoiceConf[purchaseInvoiceRow] = value;
    if (value === null) {
      localStorage.removeItem(purchaseInvoiceRow);
    } else {
      localStorage.setItem(purchaseInvoiceRow, value);
    }
  }

  collectInvoicesColumns(value, invoiceRow: any) {
    this.currentInvoiceConf[invoiceRow] = value;
    if (value === null) {
      localStorage.removeItem(invoiceRow);
    } else {
      localStorage.setItem(invoiceRow, value);
    }
  }

  collectVouchersColumns(value, voucherRow: any) {
    this.currentVoucherConf[voucherRow] = value;
    if (value === null) {
      localStorage.removeItem(voucherRow);
    } else {
      localStorage.setItem(voucherRow, value);
    }
  }

  ngAfterViewInit(): void {
    for (const purchaseInvoiceRow of this.purchaseInvoiceRows) {
      if (localStorage.getItem(purchaseInvoiceRow) !== null) {
        this.currentPurchaseInvoiceConf[purchaseInvoiceRow] = localStorage.getItem(purchaseInvoiceRow);
      }
    }

    for (const vouchersRow of this.vouchersRows) {
      if (localStorage.getItem(vouchersRow) !== null) {
        this.currentVoucherConf[vouchersRow] = localStorage.getItem(vouchersRow);
      }
    }

    for (const invoiceRow of this.invoiceRows) {
      if (localStorage.getItem(invoiceRow) !== null) {
        this.currentInvoiceConf[invoiceRow] = localStorage.getItem(invoiceRow);
      }
    }
  }
}

