import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class BaseService {
  private token: string;

  get options(): any {
    const options = {
      headers: new HttpHeaders({Accept: 'application/json', 'Access-Control-Allow-Headers': '*'}),
      withCredentials: true
    };
    return options;
  }
}
