import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ImportResult} from '../../../common/import.result';
import {BaseService} from '../base/base.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {VoucherModel} from '../../../common/voucher.model';
import {PurchaseInvoiceModel} from '../../../common/purchase.invoice.model';
import {InvoiceModel} from '../../../common/invoice.model';

@Injectable()
export class SetImportConfigurationService extends BaseService {
  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  pushPurchaseInvoicesConfiguration(data: PurchaseInvoiceModel): Observable<ImportResult> {
    return this.http.post<ImportResult>('/api/DocumentsConfiguration/purchaseInvoiceConfiguration', data, this.options)
      .pipe(catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(['logout']);
        }
        return of(null);
      }));
  }

  pushInvoicesConfiguration(data: InvoiceModel): Observable<ImportResult> {
    return this.http.post<ImportResult>('/api/DocumentsConfiguration/invoiceConfiguration', data, this.options)
      .pipe(catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(['logout']);
        }
        return of(null);
      }));
  }

  pushVouchersConfiguration(data: VoucherModel): Observable<ImportResult> {
    return this.http.post<ImportResult>('/api/DocumentsConfiguration/vouchersConfiguration', data, this.options)
      .pipe(catchError((err) => {
        if (err.status === 401) {
          this.router.navigate(['logout']);
        }
        return of(null);
      }));
  }
}
